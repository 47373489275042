import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../src/assets/css/normalize.css'
import './assets/css/common.css';
import './assets/css/hover.css';
Vue.config.productionTip = false

Vue.use(ElementUI); 

/**
 * 判断某个原生DOM元素是否不在屏幕可见区内
 * @param {*} el 原生DOM元素
 */
const isElementNotInViewport = function(el) {
  let rect = el.getBoundingClientRect();
  // console.log(rect.top,window.innerHeight,document.documentElement.clientHeight);
  return (
    rect.top >= (window.innerHeight || document.documentElement.clientHeight) ||
    rect.bottom <= 0
  );
};
 
Vue.directive("animation",{ // 可视区域 加载动画指令
  inserted(el, binding){
    window.addEventListener("scroll",()=>{  //滚动条 
        if(!isElementNotInViewport(el)){
            if(el.className.split(' ').indexOf(binding.value) == -1){
                el.className = `${el.className} ${binding.value}`
            }
        }
    })
    window.addEventListener("wheel",()=>{ //鼠标滚轮
      function parentNode (node){ //递归查找父节点是否存在 is-active 类名
          if(!node.parentNode) return false;  
          let classArr = []
          if(node.parentNode.className){
            classArr = node.parentNode.className.split(' ')
          }
          if(classArr.indexOf('is-active') == -1 ){  
            parentNode(node.parentNode)
          }else{ 
            setNode()
          } 
      }    
      function setNode (){ //给当前节点 添加 class 名
          if(el.className.split(' ').indexOf(binding.value) == -1){
            el.className = `${el.className} ${binding.value}`
          }
      } 
      if(binding.arg === 'home'){
        if(el.className.split(' ').indexOf('is-active') == -1){
          parentNode(el);
        }else{
          setNode()
        }
      }
    })
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
