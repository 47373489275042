/*
 * @Author: inwen6
 * @Date: 2020-12-11 09:29:24
 * @LastEditTime: 2020-12-11 14:37:24
 * @LastEditors: your name
 * @Description: 
 * @FilePath: /hheiqngting-gw/src/router/index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/About.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('../views/product.vue')
  },
  {
    path: '/join',
    name: 'join',
    component: () => import('../views/join.vue')
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('../views/success.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/news.vue')
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: () => import('../views/newsDetail.vue')
  },
  {
    path: '/Aboutwe',
    name: 'Aboutwe',
    component: () => import('../views/Aboutwe.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  base: process.env.BASE_URL,
  routes
})

export default router
