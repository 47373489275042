<template>
  <div id="app">
    <el-row class="nav-top" id="nav-top" v-show="!drawer">
      <el-col :xs="0" :span="2" class="nav"></el-col>
      <el-col :xs="24" :span="21" class="nav mainNav">
        <el-row class="navBox">
          <el-col :xs="4" :span="4" class="navBox navimg">
            <el-image
              class="logoimg"
              :src="require('./assets/logo.png')"
              fit="fill"
            ></el-image>
          </el-col>
          <el-col :xs="16" :sm="2" :md="3" :lg="8" class="navBox"></el-col>
          <el-col
            :xs="0"
            :sm="3"
            :md="2"
            :lg="2"
            class="navBox navtitle"
            @click.native="routers('/')"
            >首页</el-col
          >
          <el-col
            :xs="0"
            :sm="3"
            :md="3"
            :lg="2"
            class="navBox navtitle"
            @click.native="routers('/product')"
            >产品中心</el-col
          >
          <el-col
            :xs="0"
            :sm="3"
            :md="3"
            :lg="2"
            class="navBox navtitle"
            @click.native="routers('/join')"
            >招商加盟</el-col
          >
          <!--          <el-col :xs="0" :sm="3" :md="3" :lg="2" class="navBox navtitle" @click.native="routers('/success')"
            >成功案例</el-col
          >-->
          <el-col
            :xs="0"
            :sm="3"
            :md="3"
            :lg="2"
            class="navBox navtitle"
            @click.native="routers('/news')"
            >新闻资讯</el-col
          >
          <el-col
            :xs="0"
            :sm="3"
            :md="3"
            :lg="2"
            class="navBox navtitle"
            @click.native="routers('/Aboutwe')"
            >关于我们</el-col
          >
          <el-col :xs="4" :sm="0" :md="0" :lg="0" class="navBox navtitle">
            <div class="qhico" @click="opendrawer">
              <i class="el-icon-s-unfold"></i>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :xs="0" :span="1" class="nav"></el-col>
    </el-row>
    <router-view />
    <!-- <el-drawer
      title="我是标题"
      :visible.sync="drawer"
      :direction="direction"
      :with-header="false"
      size="60%"
      class="drawerMain"
    >
      <div class="indrawer">黑蜻蜓共享</div>
      <div class="indrawer2" @click="routers('/')">首页</div>
      <div class="indrawer2" @click="routers('/product')">产品中心</div>
      <div class="indrawer2" @click="routers('/join')">招商加盟</div>
      <div class="indrawer2" @click="routers('/success')">成功案例</div>
      <div class="indrawer2" @click="routers('/news')">新闻资讯</div>
      <div class="indrawer2" @click="routers('/Aboutwe')">关于我们</div>
    </el-drawer> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      drawer: false,
      direction: "ltr",
    };
  },
  mounted() {
    document.ondragstart = function () {
      return false;
    };
    window.addEventListener("scroll", () => {
      //隐藏首页title
      var H = document.documentElement.scrollTop || document.body.scrollTop;
      if (H > 40) {
        console.log("hhhh");
        let el = document.getElementById("nav-top"),
          classArr = el.className.split(" ") || [];
        if (classArr.indexOf("show_top") == -1) {
          el.className = `${el.className} ${"show_top"}`;
        }
      } else {
        let el = document.getElementById("nav-top"),
          classArr = el.className.split(" ") || [];
        if (classArr.indexOf("show_top") !== -1) {
          classArr.splice(classArr.indexOf("show_top"), 1);
          el.className = `${classArr.join(" ")}`;
        }
      }
    });
  },
  methods: {
    opendrawer() {
      this.drawer = true;
    },
    routers(path) {
      console.log("path", path);
      this.$router.push(path);
    },
  },
};
</script>
<style lang="scss">
::-webkit-scrollbar {
  display: none;
}
img {
  -moz-user-drag: none;
  -webkit-user-drag: none;
}
.el-drawer__body {
  background: #fff !important;
}
.show_top {
  animation-name: mymove; /*动画名称加载*/
  animation-duration: 1s; /*动画持续时间*/
  // animation-delay: 1s;  /**动画的延长时间*/
  animation-iteration-count: 1; /**动画执行的次数*/
  animation-timing-function: ease; /**动画运动的速度*/
  animation-direction: alternate; /*动画是否运行*/
  animation-fill-mode: forwards; /*保留动画状态*/
}
@keyframes mymove {
  from {
    top: 0px;
  }
  to {
    top: -100px;
  }
}
#app {
  width: 100%;
  height: 100%;
  scrollbar-color: transparent transparent;
  scrollbar-track-color: transparent;
  -ms-scrollbar-track-color: transparent;
  .drawerMain {
    .indrawer {
      width: 80%;
      height: 50px;
      margin: 0 auto;
      border-bottom: 1px solid #fff;
      font-size: 20px;
      color: #fff;
      text-align: center;
      line-height: 50px;
    }
    .indrawer2 {
      width: 80%;
      height: 40px;
      margin: 0 auto;
      border-bottom: 1px solid #fff;
      font-size: 16px;
      color: #fff;
      line-height: 40px;
    }
  }
  .nav-top {
    width: 100%;
    position: fixed;
    height: 50px;
    background: rgba($color: #000000, $alpha: 0.25);
    z-index: 100000;
    .nav {
      height: 50px;
    }
    .mainNav {
      .navimg {
        display: flex;
        align-items: center;
      }
      .navBox {
        height: 50px;
        .qhico {
          width: 100%;
          height: 100%;
          font-size: 40px;
        }
      }
      .navtitle {
        text-align: center;
        line-height: 50px;
        font-size: 14px;
        color: #fff;
        font-weight: normal;
        cursor: pointer;
      }
    }
  }
}
</style>
